import React, { useRef, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import Feed from './Feed';
import poster from '../database/poster/video.png';
import nextPoster from '../database/poster/new.png';

export const Home = () => {
    const [mute, setMute] = useState(true);
    return (
        <main>
            <Helmet>
                <title>Ghaza Server</title>
            </Helmet>
            <div className="home-feed hf df fc" id='feed' >
                <Feed src="post.jpg" type="image" avatar="user.jpg" mute={mute} setMute={setMute} />
                <Feed src="video.mp4" type="video" avatar="user.jpg" mute={mute} setMute={setMute} />
                <Feed src="new.mp4" type="video" avatar="user.jpg" mute={mute} setMute={setMute} />
                <div className="getting-posts wf df jc ac" aria-disabled="false">
                    <div className="loader"></div>
                </div>
            </div>
        </main>
    )
}
