import React from 'react'
import { Helmet } from 'react-helmet-async';

export const Upload = () => {
  return (
    <main>
      <Helmet>
        <title>Upload • Ghaza Server</title>
      </Helmet>
      <div>Upload</div>
    </main>
  )
}
