import React from 'react'
import { Link } from 'react-router-dom';

export const E404 = () => {
  return (
    <main>
      <div className="error-404 wf hf df jc ac fc">
        <svg viewBox="0 0 16 16" >
          <g clipPath="url(#clip0_226_64)">
            <path d="M7.99992 14.6666C11.6818 14.6666 14.6666 11.6819 14.6666 7.99998C14.6666 4.31808 11.6818 1.33331 7.99992 1.33331C4.31802 1.33331 1.33325 4.31808 1.33325 7.99998C1.33325 11.6819 4.31802 14.6666 7.99992 14.6666Z" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10.6666 10.6666C10.6666 10.6666 9.66659 9.33331 7.99992 9.33331C6.33325 9.33331 5.33325 10.6666 5.33325 10.6666" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M6 6H6.00667" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10 6H10.0067" strokeLinecap="round" strokeLinejoin="round" />
          </g>
          <defs>
            <clipPath id="clip0_226_64">
              <rect width="16" height="16" fill="white" />
            </clipPath>
          </defs>
        </svg>
        <p>404 Page note found</p>
        <Link to="/" >
          <button>Return Home</button>
        </Link>
      </div>
    </main>
  )
}
