import React from 'react'
import { Helmet } from 'react-helmet-async';

export const Explore = () => {
    return (
        <main>
            <Helmet>
                <title>Explore • Ghaza Server</title>
            </Helmet>
            <div>Explore</div>
        </main>
    )
}
