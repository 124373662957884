import React from 'react'
import { Helmet } from 'react-helmet-async';

export const Search = () => {
  return (
    <main>
      <Helmet>
        <title>Search • Ghaza Server</title>
      </Helmet>
      <div>Search</div>
    </main>
  )
}
