import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Home } from './components/Home';
import { Search } from './components/Search';
import { Upload } from './components/Upload';
import { Reels } from './components/Reels';
import { User } from './components/User';
import { E404 } from './components/E404';
import { NavBar } from './components/NavBar';
import { Header } from './components/Header';
import { Notifications } from './components/Notifications';
import { Explore } from './components/Explore';
import { Authentication } from './components/Authentication';


function App() {
  window.oncontextmenu = (e) => {
    e.preventDefault();
  }
  const [theme, setTheme] = useState({
    whiteColor: '#fff',
    blackColor: '#000',
    rootColor: '#E4E4E4',
  });
  const [currentTheme, setCurrentTheme] = useState('light');
  const toggleTheme = () => {
    setCurrentTheme(currentTheme === 'light' ? 'dark' : 'light');
    setTheme((prevTheme) => ({
      whiteColor: prevTheme.whiteColor === '#fff' ? '#000' : '#fff',
      blackColor: prevTheme.blackColor === '#000' ? '#fff' : '#000',
      rootColor: prevTheme.rootColor === '#E4E4E4' ? '#191919' : '#E4E4E4',
    }));
  };
  document.body.style.setProperty('--whiteColor', theme.whiteColor);
  document.body.style.setProperty('--blackColor', theme.blackColor);
  document.body.style.setProperty('--rootColor', theme.rootColor);
  return (
    <div className='ghazaserver'>
      <HelmetProvider>
        <Router>
          <Header toggleTheme={toggleTheme} theme={currentTheme} />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/search" element={<Search />} />
            <Route path="/upload" element={<Upload />} />
            <Route path="/reels" element={<Reels />} />
            <Route path="/user" element={<User />} />
            <Route path="/notifications" element={<Notifications />} />
            <Route path="/explore" element={<Explore />} />
            <Route path="/authentication" element={<Authentication />} />
            <Route path="*" element={<E404 />} />
          </Routes>
          <NavBar />
        </Router >
      </HelmetProvider>
    </div>
  );
}

export default App;
