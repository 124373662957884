import React, { useRef, useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';

const FeedComponent = ({ src, mute, setMute, avatar, type }) => {
    const avatarPath = require(`../database/profile/${avatar}`);
    const srcPath = require(`../database/feed/${src}`);
    const videoRef = useRef(null);
    const { ref, inView } = useInView({
        threshold: 0.5,
        triggerOnce: false,
    });

    useEffect(() => {
        if (videoRef.current) {
            if (inView) {
                videoRef.current.play();
            } else {
                videoRef.current.pause();
            }
        }
    }, [inView]);

    const moreToggle = (e) => {
        const element = e.currentTarget.parentNode.children[2];
        const blur = e.currentTarget.parentNode.children[3];
        if (element.getAttribute('aria-checked') === 'true') {
            element.setAttribute('aria-checked', false);
            blur.setAttribute('aria-checked', false);
        } else {
            element.setAttribute('aria-checked', true);
            blur.setAttribute('aria-checked', true);
        }
    }

    return (
        <div className="algo-post df js as fc" ref={ref} >
            <div className="post-header df jb ac wf">
                <div className="user-det df js ac">
                    <img src={avatarPath} alt="Ghaza Server" />
                    <div className="col-align df jc as fc">
                        <p>Follow</p>
                        <h2>Muhammad Jazib</h2>
                    </div>
                </div>
                <svg viewBox="0 0 16 16" onClick={(e) => { moreToggle(e) }} >
                    <path d="M7.99992 8.66671C8.36811 8.66671 8.66659 8.36823 8.66659 8.00004C8.66659 7.63185 8.36811 7.33337 7.99992 7.33337C7.63173 7.33337 7.33325 7.63185 7.33325 8.00004C7.33325 8.36823 7.63173 8.66671 7.99992 8.66671Z" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M7.99992 3.99996C8.36811 3.99996 8.66659 3.70148 8.66659 3.33329C8.66659 2.9651 8.36811 2.66663 7.99992 2.66663C7.63173 2.66663 7.33325 2.9651 7.33325 3.33329C7.33325 3.70148 7.63173 3.99996 7.99992 3.99996Z" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M7.99992 13.3333C8.36811 13.3333 8.66659 13.0349 8.66659 12.6667C8.66659 12.2985 8.36811 12 7.99992 12C7.63173 12 7.33325 12.2985 7.33325 12.6667C7.33325 13.0349 7.63173 13.3333 7.99992 13.3333Z" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                <div className="-header-actions df fc" aria-checked="false">
                    <p className='df js ac' >
                        <svg viewBox="0 0 16 16" >
                            <g clipPath="url(#clip0_226_75)">
                                <path d="M7.99992 14.6666C11.6818 14.6666 14.6666 11.6819 14.6666 7.99998C14.6666 4.31808 11.6818 1.33331 7.99992 1.33331C4.31802 1.33331 1.33325 4.31808 1.33325 7.99998C1.33325 11.6819 4.31802 14.6666 7.99992 14.6666Z" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M5.33325 8L7.99992 10.6667L10.6666 8" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M8 5.33331V10.6666" strokeLinecap="round" strokeLinejoin="round" />
                            </g>
                            <defs>
                                <clipPath id="clip0_226_75">
                                    <rect width="16" height="16" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                        <span>Download</span>
                    </p>
                    <p className='df js ac' >
                        <svg viewBox="0 0 24 24" className='stroke-width' >
                            <path d="M10 13C10.4295 13.5742 10.9774 14.0492 11.6066 14.393C12.2357 14.7367 12.9315 14.9411 13.6467 14.9924C14.3618 15.0436 15.0796 14.9404 15.7513 14.6898C16.4231 14.4392 17.0331 14.0471 17.54 13.54L20.54 10.54C21.4508 9.59702 21.9548 8.334 21.9434 7.02302C21.932 5.71204 21.4061 4.45797 20.4791 3.53093C19.5521 2.60389 18.298 2.07805 16.987 2.06666C15.676 2.05526 14.413 2.55924 13.47 3.47003L11.75 5.18003" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M14 11C13.5705 10.4259 13.0226 9.9508 12.3934 9.60704C11.7642 9.26328 11.0684 9.05886 10.3533 9.00765C9.63816 8.95643 8.92037 9.05961 8.24861 9.3102C7.57685 9.56079 6.96684 9.95291 6.45996 10.46L3.45996 13.46C2.54917 14.403 2.04519 15.666 2.05659 16.977C2.06798 18.288 2.59382 19.542 3.52086 20.4691C4.4479 21.3961 5.70197 21.922 7.01295 21.9334C8.32393 21.9447 9.58694 21.4408 10.53 20.53L12.24 18.82" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        <span>Copy Link</span>
                    </p>
                    <p className='df js ac' >
                        <svg viewBox="0 0 24 24" className='stroke-width' >
                            <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M12 8V12" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M12 16H12.01" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        <span>Report</span>
                    </p>
                </div>
                <div className="blur" aria-checked="false" onClick={(e) => { moreToggle(e) }} ></div>
            </div>
            <div className="post-caption">
                She sing for the freedom 💝✨
            </div>
            <div className="post-content df js ac">
                <div className="post-date">June 5 2024</div>
                <div className="post-fetch" onClick={() => { setMute(mute === true ? false : true) }}>
                    {type === 'video' ? (
                        <>
                            <video className='ghazaserverVideo' alt="Ghaza Server" ref={videoRef} muted={mute} >
                                <source src={srcPath} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                            <div className="volume-toggle df jc ac" onClick={() => { setMute(mute === true ? false : true) }} aria-disabled={mute}>
                                <svg viewBox="0 0 11 16">
                                    <path d="M10 1L5 5H1V11H5L10 15V1Z" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                <div className="volume"></div>
                            </div>
                        </>
                    ) : (
                        <img src={srcPath} alt="Ghaza Server" />
                    )}
                </div>
            </div>
            <div className="post-actions dg wf">
                <div className="action-def wf hf df jc ac" aria-checked="true">
                    <svg viewBox="0 0 16 16" >
                        <g clipPath="url(#clip0_226_64)">
                            <path d="M7.99992 14.6666C11.6818 14.6666 14.6666 11.6819 14.6666 7.99998C14.6666 4.31808 11.6818 1.33331 7.99992 1.33331C4.31802 1.33331 1.33325 4.31808 1.33325 7.99998C1.33325 11.6819 4.31802 14.6666 7.99992 14.6666Z" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M10.6666 10.6666C10.6666 10.6666 9.66659 9.33331 7.99992 9.33331C6.33325 9.33331 5.33325 10.6666 5.33325 10.6666" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M6 6H6.00667" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M10 6H10.0067" strokeLinecap="round" strokeLinejoin="round" />
                        </g>
                        <defs>
                            <clipPath id="clip0_226_64">
                                <rect width="16" height="16" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                    <p>789</p>
                </div>
                <div className="action-def wf hf df jc ac">
                    <svg viewBox="0 0 16 16" >
                        <g clipPath="url(#clip0_226_58)">
                            <path d="M7.99992 14.6666C11.6818 14.6666 14.6666 11.6819 14.6666 7.99998C14.6666 4.31808 11.6818 1.33331 7.99992 1.33331C4.31802 1.33331 1.33325 4.31808 1.33325 7.99998C1.33325 11.6819 4.31802 14.6666 7.99992 14.6666Z" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M5.33325 9.33331C5.33325 9.33331 6.33325 10.6666 7.99992 10.6666C9.66659 10.6666 10.6666 9.33331 10.6666 9.33331" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M6 6H6.00667" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M10 6H10.0067" strokeLinecap="round" strokeLinejoin="round" />
                        </g>
                        <defs>
                            <clipPath id="clip0_226_58">
                                <rect width="16" height="16" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                    <p>0</p>
                </div>
                <div className="action-def wf hf df jc ac">
                    <svg viewBox="0 0 16 16" >
                        <path d="M14 7.66669C14.0023 8.5466 13.7967 9.41461 13.4 10.2C12.9296 11.1412 12.2065 11.9328 11.3116 12.4862C10.4168 13.0396 9.3855 13.3329 8.33333 13.3334C7.45342 13.3356 6.58541 13.1301 5.8 12.7334L2 14L3.26667 10.2C2.86995 9.41461 2.66437 8.5466 2.66667 7.66669C2.66707 6.61452 2.96041 5.58325 3.51381 4.68839C4.06722 3.79352 4.85884 3.0704 5.8 2.60002C6.58541 2.20331 7.45342 1.99772 8.33333 2.00002H8.66667C10.0562 2.07668 11.3687 2.66319 12.3528 3.64726C13.3368 4.63132 13.9233 5.94379 14 7.33335V7.66669Z" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    <p>89</p>
                </div>
                <div className="action-def wf hf df jc ac">
                    <svg viewBox="0 0 16 16" >
                        <g clipPath="url(#clip0_226_75)">
                            <path d="M7.99992 14.6666C11.6818 14.6666 14.6666 11.6819 14.6666 7.99998C14.6666 4.31808 11.6818 1.33331 7.99992 1.33331C4.31802 1.33331 1.33325 4.31808 1.33325 7.99998C1.33325 11.6819 4.31802 14.6666 7.99992 14.6666Z" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M5.33325 8L7.99992 10.6667L10.6666 8" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M8 5.33331V10.6666" strokeLinecap="round" strokeLinejoin="round" />
                        </g>
                        <defs>
                            <clipPath id="clip0_226_75">
                                <rect width="16" height="16" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                    <p>68</p>
                </div>
                <div className="action-def wf hf df jc ac">
                    <svg viewBox="0 0 16 16" >
                        <g clipPath="url(#clip0_226_76)">
                            <path d="M14.6666 1.33331L7.33325 8.66665" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M14.6666 1.33331L9.99992 14.6666L7.33325 8.66665L1.33325 5.99998L14.6666 1.33331Z" strokeLinecap="round" strokeLinejoin="round" />
                        </g>
                        <defs>
                            <clipPath id="clip0_226_76">
                                <rect width="16" height="16" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                    <p>43</p>
                </div>
            </div>
        </div>
    );
};

export default FeedComponent;
