import React from 'react'
import { Link, useLocation } from 'react-router-dom';

export const Header = ({ theme, toggleTheme }) => {

  return (
    <header className='df jb ac'>
      <div className="logo df js ac wf hf"><Link to="/" >Ghaza Server</Link></div>
      <div className="header-nav df je ac wf hf">
        <div className="theme-toggler df jc ac" onClick={toggleTheme} aria-label={theme}>
          <svg viewBox="0 0 14 14" >
            <path d="M12.2499 7.46083C12.1582 8.45379 11.7855 9.40007 11.1756 10.189C10.5656 10.9778 9.74365 11.5767 8.80577 11.9155C7.86789 12.2542 6.85293 12.3189 5.87965 12.1019C4.90637 11.8849 4.01502 11.3952 3.30991 10.69C2.60479 9.98492 2.11507 9.09357 1.89806 8.12029C1.68104 7.14701 1.74569 6.13205 2.08446 5.19417C2.42323 4.2563 3.02209 3.4343 3.81098 2.82436C4.59987 2.21442 5.54616 1.84176 6.53911 1.75C5.95777 2.53649 5.67802 3.50551 5.75075 4.48083C5.82348 5.45614 6.24386 6.37295 6.93543 7.06452C7.62699 7.75608 8.54381 8.17646 9.51912 8.24919C10.4944 8.32192 11.4635 8.04218 12.2499 7.46083Z" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
          <svg viewBox="0 0 14 14" >
            <g clipPath="url(#clip0_274_7)">
              <path d="M6.99992 9.91671C8.61075 9.91671 9.91658 8.61087 9.91658 7.00004C9.91658 5.38921 8.61075 4.08337 6.99992 4.08337C5.38909 4.08337 4.08325 5.38921 4.08325 7.00004C4.08325 8.61087 5.38909 9.91671 6.99992 9.91671Z" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M7 0.583374V1.75004" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M7 12.25V13.4167" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M2.46167 2.46167L3.29 3.29" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M10.71 10.71L11.5383 11.5383" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M0.583252 7H1.74992" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M12.25 7H13.4167" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M2.46167 11.5383L3.29 10.71" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M10.71 3.29L11.5383 2.46167" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
              <clipPath id="clip0_274_7">
                <rect width="14" height="14" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
        <Link to="/explore" >
          <svg viewBox="0 0 14 14" >
            <path d="M9.91658 12.25V11.0833C9.91658 10.4645 9.67075 9.871 9.23317 9.43342C8.79558 8.99583 8.20209 8.75 7.58325 8.75H2.91659C2.29775 8.75 1.70425 8.99583 1.26667 9.43342C0.829085 9.871 0.583252 10.4645 0.583252 11.0833V12.25" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M5.25008 6.41667C6.53875 6.41667 7.58341 5.372 7.58341 4.08333C7.58341 2.79467 6.53875 1.75 5.25008 1.75C3.96142 1.75 2.91675 2.79467 2.91675 4.08333C2.91675 5.372 3.96142 6.41667 5.25008 6.41667Z" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M13.4167 12.25V11.0833C13.4164 10.5663 13.2443 10.0641 12.9275 9.65549C12.6108 9.24689 12.1673 8.95505 11.6667 8.82581" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M9.33325 1.82581C9.83516 1.95431 10.28 2.24621 10.5977 2.65549C10.9154 3.06476 11.0878 3.56812 11.0878 4.08622C11.0878 4.60432 10.9154 5.10769 10.5977 5.51696C10.28 5.92623 9.83516 6.21813 9.33325 6.34664" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </Link>
        <Link to="/notifications" >
          <svg viewBox="0 0 14 14" >
            <path d="M10.5 4.66663C10.5 3.73837 10.1313 2.84813 9.47487 2.19175C8.8185 1.53537 7.92826 1.16663 7 1.16663C6.07174 1.16663 5.1815 1.53537 4.52513 2.19175C3.86875 2.84813 3.5 3.73837 3.5 4.66663C3.5 8.74996 1.75 9.91663 1.75 9.91663H12.25C12.25 9.91663 10.5 8.74996 10.5 4.66663Z" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8.00906 12.25C7.9065 12.4268 7.7593 12.5735 7.58219 12.6756C7.40508 12.7776 7.20428 12.8313 6.99989 12.8313C6.7955 12.8313 6.5947 12.7776 6.41759 12.6756C6.24048 12.5735 6.09328 12.4268 5.99072 12.25" strokeLinecap="round" strokeLinejoin="round" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </Link>
      </div>
    </header>
  )
}
