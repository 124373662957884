import React from 'react'
import { Helmet } from 'react-helmet-async';

export const Notifications = () => {
    return (
        <main>
            <Helmet>
                <title>Notifications • Ghaza Server</title>
            </Helmet>
            <div>Notifications</div>
        </main>
    )
}
