import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

export const NavBar = () => {
    const [route, setRoute] = useState();
    const location = useLocation();
    React.useEffect(() => {
        setRoute(location.pathname);
    }, [location]);

    return (
        <nav className='df jc ac' >
            <ul className='df je ac wf hf'>
                <li className='wf df jc ac hf'>
                    <Link to="/" >
                        <svg viewBox="0 0 16 16" aria-checked={route === '/' ? true : false} >
                            <path d="M2 5.99998L8 1.33331L14 5.99998V13.3333C14 13.6869 13.8595 14.0261 13.6095 14.2761C13.3594 14.5262 13.0203 14.6666 12.6667 14.6666H3.33333C2.97971 14.6666 2.64057 14.5262 2.39052 14.2761C2.14048 14.0261 2 13.6869 2 13.3333V5.99998Z" />
                            <path d="M6 14.6667V8H10V14.6667" />
                            <path d="M6 14.6667V8H10V14.6667" />
                        </svg>
                    </Link>
                </li>
                <li className='wf df jc ac hf'>
                    <Link to="/search">
                        <svg viewBox="0 0 16 16" aria-checked={route === '/search' ? true : false}>
                            <g clipPath="url(#clip0_230_20)">
                                <path d="M7.33333 12.6667C10.2789 12.6667 12.6667 10.2789 12.6667 7.33333C12.6667 4.38781 10.2789 2 7.33333 2C4.38781 2 2 4.38781 2 7.33333C2 10.2789 4.38781 12.6667 7.33333 12.6667Z" />
                                <path d="M14.0001 14L11.1001 11.1" />
                            </g>
                            <defs>
                                <clipPath id="clip0_230_20">
                                    <rect width="16" height="16" />
                                </clipPath>
                            </defs>
                        </svg>
                    </Link>
                </li>
                <li className='wf df jc ac hf'>
                    <Link to="/upload">
                        <svg viewBox="0 0 16 16" aria-checked={route === '/upload' ? true : false}>
                            <path d="M14 10V12.6667C14 13.0203 13.8595 13.3594 13.6095 13.6095C13.3594 13.8595 13.0203 14 12.6667 14H3.33333C2.97971 14 2.64057 13.8595 2.39052 13.6095C2.14048 13.3594 2 13.0203 2 12.6667V10" />
                            <path d="M11.3334 5.33333L8.00008 2L4.66675 5.33333" />
                            <path d="M8 2V10" />
                        </svg>
                    </Link>
                </li>
                <li className='wf df jc ac hf'>
                    <Link to="/reels">
                        <svg viewBox="0 0 16 16" aria-checked={route === '/reels' ? true : false}>
                            <path d="M2.66675 7.33331C4.25805 7.33331 5.78417 7.96545 6.90939 9.09067C8.03461 10.2159 8.66675 11.742 8.66675 13.3333" />
                            <path d="M2.66675 2.66669C5.49572 2.66669 8.20883 3.79049 10.2092 5.79088C12.2096 7.79127 13.3334 10.5044 13.3334 13.3334" />
                            <path d="M3.33341 13.3333C3.7016 13.3333 4.00008 13.0349 4.00008 12.6667C4.00008 12.2985 3.7016 12 3.33341 12C2.96522 12 2.66675 12.2985 2.66675 12.6667C2.66675 13.0349 2.96522 13.3333 3.33341 13.3333Z" />
                        </svg>
                    </Link>
                </li>
                <li className='wf df jc ac hf'>
                    <Link to="/user">
                        <svg viewBox="0 0 16 16" aria-checked={route === '/user' ? true : false}>
                            <path d="M13.3334 14V12.6667C13.3334 11.9594 13.0525 11.2811 12.5524 10.781C12.0523 10.281 11.374 10 10.6667 10H5.33341C4.62617 10 3.94789 10.281 3.4478 10.781C2.9477 11.2811 2.66675 11.9594 2.66675 12.6667V14" />
                            <path d="M7.99992 7.33333C9.47268 7.33333 10.6666 6.13943 10.6666 4.66667C10.6666 3.19391 9.47268 2 7.99992 2C6.52716 2 5.33325 3.19391 5.33325 4.66667C5.33325 6.13943 6.52716 7.33333 7.99992 7.33333Z" />
                        </svg>
                    </Link>
                </li>
            </ul>
        </nav>
    );
};