import React from 'react'
import { Helmet } from 'react-helmet-async';

export const Reels = () => {
  return (
    <main>
      <Helmet>
        <title>Reels • Ghaza Server</title>
      </Helmet>
      <div>Reels</div>
    </main>
  )
}
