import React from 'react'
import { Helmet } from 'react-helmet-async';

export const User = () => {
    return (
        <main>
            <Helmet>
                <title>User • Ghaza Server</title>
            </Helmet>
            <div>User</div>
        </main>
    )
}
